nav {
    width: 100vw;
    display: flex;
    justify-content: space-between;
    background-color: #000000;
    align-items: center;
}
nav h1{
    color: #fff;
    margin-left: 40px;
}

nav span{
    color: #fff;
    margin-left: 40px;
}

.nav-options{
    display: flex;
    align-items: center;
}



nav span{
    cursor: pointer;
    margin-left: 40px;
    font-size: .85rem;
    text-transform: uppercase;
}
nav span:hover{
    color: #fff;
    border-bottom: 2px solid #EE9B00;
    padding-bottom: 5px;
}

.input-group{
    display: flex;
    align-items: center;
}
.input-group input{
    position: relative;
    width: 323px;
    height: 4vh;
    border-radius: 16px;
    border: none;
    margin-right: 30px;
}
.input-group input[type='text']{
    padding-left: 17px;
    font-size: 1rem;
    color: black;
}
.input-group input::placeholder{
    color: rgb(0, 0, 0);
}
.input-group input:focus{
    outline: none;
}
.popup{
    box-shadow: rgb(0, 0, 0) 0px 1px 60px;
    left: 27%;
    position: absolute;
    background-color: white;
    z-index: 8;
    padding: 32px;
    top: 20%;
    width: 700px;
    height: 920px;
    border-radius: 8px;
    padding: 16px;
}
.X{
    z-index: 9;
    align-self: flex-end;
    position: absolute;
    top: 8px;
    right: 8px;
}
.prepopup{
    display: flex;
    justify-content: center;
    left: 40%;
}
.card {
    margin-left: 15px;
}
.movieSearch {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    align-items: center;
    flex-direction: row;
    position: absolute;

}